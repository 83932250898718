import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  FormGroup,
  Grid,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Import the orders.co logo (you need to have the logo image in your project)
// Example: import logo from './ordersco-logo.png';

const makeRandomPass = (
  length,
  useUppercase,
  useLowercase,
  useNumbers,
  useSymbols,
  customSymbols
) => {
  let text = "";
  let possibleUppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let possibleLowercase = "abcdefghijklmnopqrstuvwxyz";
  let possibleNumbers = "0123456789";
  let possibleSymbols = customSymbols || "!@#$%^&*()_+";
  let possible = "";

  if (useUppercase) possible += possibleUppercase;
  if (useLowercase) possible += possibleLowercase;
  if (useNumbers) possible += possibleNumbers;
  if (useSymbols) possible += possibleSymbols;

  if (possible.length === 0) return "";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#1C1E21", // Adjust this to match orders.co theme colors
    },
    background: {
      default: "#f0f2f5", // Adjust this to match orders.co background
    },
  },
});

const App = () => {
  const [password, setPassword] = useState("**********************");
  const [useUppercase, setUseUppercase] = useState(true);
  const [useLowercase, setUseLowercase] = useState(true);
  const [useNumbers, setUseNumbers] = useState(true);
  const [useSymbols, setUseSymbols] = useState(true);
  const [customSymbols, setCustomSymbols] = useState("!@#$%^&*()_+");
  const [passwordLength, setPasswordLength] = useState(18);

  const generatePassword = () => {
    const length = parseInt(passwordLength, 10) || 18;
    const randomPassword = makeRandomPass(
      length,
      useUppercase,
      useLowercase,
      useNumbers,
      useSymbols,
      customSymbols
    );

    if (randomPassword === "") {
      toast.error("Please select at least one character type.");
      return;
    }

    setPassword(randomPassword);

    navigator.clipboard
      .writeText(randomPassword)
      .then(() => {
        toast.success("Password copied to your clipboard");
      })
      .catch((err) => {
        toast.error("Failed to copy password to clipboard");
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Box
          sx={{
            bgcolor: "background.default",
            padding: 4,
            borderRadius: 2,
            marginTop: 4,
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Generate a Random Password
          </Typography>

          <Box
            sx={{
              bgcolor: "#fff",
              padding: 2,
              borderRadius: 1,
              textAlign: "center",
              marginBottom: 2,
              wordWrap: "break-word",
            }}
          >
            <Typography variant="h6" sx={{ wordWrap: "break-word" }}>
              {password}
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useUppercase}
                      onChange={(e) => setUseUppercase(e.target.checked)}
                    />
                  }
                  label="Include Uppercase Letters"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useLowercase}
                      onChange={(e) => setUseLowercase(e.target.checked)}
                    />
                  }
                  label="Include Lowercase Letters"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useNumbers}
                      onChange={(e) => setUseNumbers(e.target.checked)}
                    />
                  }
                  label="Include Numbers"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useSymbols}
                      onChange={(e) => setUseSymbols(e.target.checked)}
                    />
                  }
                  label="Include Special Characters"
                />
                {useSymbols && (
                  <TextField
                    label="Custom Special Characters"
                    value={customSymbols}
                    onChange={(e) => setCustomSymbols(e.target.value)}
                    fullWidth
                    sx={{ marginTop: 2 }}
                  />
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Password Length"
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                value={passwordLength}
                onChange={(e) => setPasswordLength(e.target.value)}
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={generatePassword}
                sx={{ marginTop: 2 }}
              >
                Generate Password
              </Button>
            </Grid>
          </Grid>
        </Box>

        <ToastContainer />
      </Container>
    </ThemeProvider>
  );
};

export default App;
